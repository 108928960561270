import {ClientEnvironment} from "@42maru/keeper-web-tool";


export const API_HOST = process.env.REACT_APP_KEEPER_API_HOST ?? '';
export const KEEPER_HOST = process.env.REACT_APP_KEEPER_HOST ?? '';

export const DISABLED_SIGNUP = JSON.parse(process.env.REACT_APP_DISABLED_SIGNUP || 'false' as string);

export const ENABLED_COGNITO = JSON.parse(process.env.REACT_APP_ENABLED_COGNITO || 'false' as string);

export const DEFAULT_ENVIRONMENT = process.env.REACT_APP_KEEPER_CLIENT_ENVIRONMENT as ClientEnvironment;

export const ENABLE_PASSWORD_HASHING = JSON.parse(process.env.REACT_APP_ENABLE_PASSWORD_HASHING || 'false' as string);
export const PASSWORD_HASH_SALT = process.env.REACT_APP_PASSWORD_HASH_SALT ?? '';

// export const PASSWORD_RULES = {
//     number: JSON.parse(process.env.REACT_APP_PASSWORD_RULE_NUMBER || 'false' as string),
//     lowercase: JSON.parse(process.env.REACT_APP_PASSWORD_RULE_LOWERCASE || 'false' as string),
//     uppercase: JSON.parse(process.env.REACT_APP_PASSWORD_RULE_UPPERCASE || 'false' as string),
//     special: JSON.parse(process.env.REACT_APP_PASSWORD_RULE_SPECIAL || 'false' as string),
//     min: JSON.parse(process.env.REACT_APP_PASSWORD_RULE_MIN || '4' as string),
//     max: JSON.parse(process.env.REACT_APP_PASSWORD_RULE_MAX || '32' as string)
// }
